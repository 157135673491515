<template>
  <nav class="navigation">
    <div class="logo">
      <div v-if="isLocal">{{ MODE_NAME }} 개발환경</div>
    </div>
    <div class="user" v-if="userName">
      <div>
        <span>{{ userName }}</span> 안녕하세요!
        <!-- <div>foo</div> -->
      </div>
      <div>
        <router-link tag="sp-button" to="/logout" class="big">로그아웃</router-link>
      </div>
    </div>
    <div class="services">
      <span>🌏 관리 서비스 분류</span>
      <sp-select>
        <option value="">빌딩플러스</option>
      </sp-select>
    </div>

    <ul class="main-navigation">
      <template v-for="(menu, i) in tree">
          <li :key="i" v-if="accessibleParent.includes(menu.menuNo)">
            <router-link
            :to="menu.menuURL"
            :class="{ home: i == 0 }"
            :style="{ pointerEvent: !menu.menuURL ? 'none' : 'auto' }">
              <template v-if="i == 0"><img src="@/assets/view_quilt.svg" alt="smartoffice" loading="lazy"></template> {{ menu.menuNm }}
            </router-link>
            <ul v-if="menu.child" class="sub-navigation">
              <li
              v-for="(childMenu, j) in menu.child"
              :key="j"
              v-show="accessibleChild.includes(childMenu.menuNo)">
                <router-link :to="childMenu.menuURL" :class="{'active': new RegExp('^'+childMenu.menuURLMatchRegex+'$').test($route.path)}">
                  - {{ childMenu.menuNm }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>
    </ul>
   </nav>
</template>
<script>
import LogoSparkplus from '@/components/common/LogoSparkplus'

export default {
  mounted () { },
  computed: {
    tree () {
      return this.$store.getters.tree
    },
    accessibleParent () {
      const parent = []
      for (let i = 0; i < this.tree.length; i++) {
        for (let j = 0; j < this.tree[i].child.length; j++) {
          if (this.accessibleChild.includes(this.tree[i].child[j].menuNo)) {
            parent.push(this.tree[i].child[j].parentMenuNo)
            break
          }
        }
      }
      return parent
    },
    accessibleChild () {
      return this.$store.getters['user/accessibleMenus']
    },
    userName () {
      return this.$store.getters['user/userName']
    },
    MODE_NAME () {
      if (this.isProduction) {
        return ''
      }
      return process.env.VUE_APP_MODE_KR ?? 'DEV'
    },
    isLocal () {
      return process.env.VUE_APP_MODE_KR === 'local'
    },
    isProduction () {
      return process.env.VUE_APP_MODE === 'production'
    },
  },
  components: { LogoSparkplus },
}
</script>

<style lang="scss" scoped>

.navigation {
  // display: block;
  height: 100%;
  overflow: auto;
  user-select: none;
  padding-bottom: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 320px;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  // transition: width 0.2s cubic-bezier(.5,0,.5,1);
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .active {
    font-weight: 800;
    color: var(--theme-primary-color);
  }
}
.main-navigation {
  margin: 20px 50px 0 50px;
  padding: 0;
  >li {
    font-size: 18px;
    line-height: 20px;
    > a {
      color: #fff;
      display: inline-block;
      margin: 20px 0 5px 0;
    }
  }
}
.sub-navigation {
  padding-left: 20px;

  >li {
    font-size: 16px;
    > a {
      display: inline-block;
      color: #fff;
      margin: 5px 0;
    }
    // list-style-type: '- ';
  }
}
.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;

}
.services {
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;;
  }
  margin: 20px 40px 0 40px;
}
.logo {
  font-family: "Pretendard Variable", Pretendard;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 44.2px;
  width: 100%;
  margin-top: 65px;
  text-align: center;
  margin-bottom: 36px;
  div {
    font-size: 30px;
    color: var(--theme-secondary-color);
  }
}
</style>
