export default [
  {
    path: '/product/membership',
    name: 'ProductMembership',
    component: () => import('@/components/product/membership/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/membership/create',
    name: 'ProductMembershipRegist',
    component: () => import('@/components/product/membership/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/membership/:id/edit',
    name: 'ProductMembershipModify',
    component: () => import('@/components/product/membership/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/promotion',
    name: 'ProductPromotion',
    component: () => import('@/components/product/promotion/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/promotion/create',
    name: 'ProductPromotionRegist',
    component: () => import('@/components/product/promotion/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/promotion/:id/edit',
    name: 'ProductPromotionModify',
    component: () => import('@/components/product/promotion/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/solution',
    name: 'ProductSolution',
    component: () => import('@/components/product/solution/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/solution/create',
    name: 'ProductSolutionRegist',
    component: () => import('@/components/product/solution/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/solution/:id/edit',
    name: 'ProductSolutionModify',
    component: () => import('@/components/product/solution/RegistPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/homepage',
    name: 'ProductHomepage',
    component: () => import('@/components/product/homepage/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/homepage/:id/edit',
    name: 'ProductHomepageModify',
    component: () => import('@/components/product/homepage/ModifyPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/standard',
    name: 'ProductStandard',
    component: () => import('@/components/product/standard/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/standard/:id/edit',
    name: 'ProductStandardDetail',
    component: () => import('@/components/product/standard/DetailPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/policy',
    name: 'ProductPolicy',
    component: () => import('@/components/product/policy/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/product/policy/:id',
    name: 'ProductPolicyDetail',
    component: () => import('@/components/product/policy/DetailPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
]
